import React, { useCallback, useContext, useEffect, useState } from "react";
import { getAccessToken } from "../../src/services/interceptor";
import { getMISData } from "../services/mis";

const HomeContext = React.createContext({
    homeRecords: [],
    filter: null,
    getHomeRecords: () => [],
    updateFilter: () => { }
});

export const HomeProvider = ({ children }) => {
    const [homeRecords, sethomeRecords] = useState([]);
    const [filter, setFilter] = useState({
        year: "",
        month: ""
    });

    const getHomeRecords = useCallback(async (filter) => {
        try {
            const inputFilter = filter || {};
            const homeData = await getMISData(inputFilter);

            sethomeRecords(homeData.data);
        } catch (e) {
            console.log(e.message);
        }
    }, []);

    function updateFilter(filter) {
        setFilter(filter);
    }

    useEffect(() => {
        const token = getAccessToken();

        if (filter && token) {
            getHomeRecords(filter);
        }
    }, [getHomeRecords, filter]);

    return (<HomeContext.Provider value={{
        getHomeRecords,
        updateFilter,
        homeRecords,
        filter
    }}>
        {children}
    </HomeContext.Provider>)
}

export function useHome() {
    const homeContext = useContext(HomeContext);

    return homeContext;
}