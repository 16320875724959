import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Layout = (props) => {
    const location = useLocation();

    const handleLogout = (e) => {
        localStorage.clear();
    }

    return (
        <>
            <header>
                <ul>
                    <li><NavLink to="/home">Home</NavLink></li>
                    <li><NavLink to="/upload-base">Upload Base</NavLink></li>
                    <li><NavLink to="/number-detail">Number Detail</NavLink></li>
                    <li><NavLink to="/un-sub">Unsub</NavLink></li>
                    <li><NavLink to="/mis">MIS</NavLink></li>
                    <li><NavLink to="/login" onClick={handleLogout}>Logout</NavLink></li>
                    {/* <li style="float:right"><a class="active" href="#about">About</a></li> */}
                </ul>
            </header>
            {location.pathname === ('/upload-base' || '/un-sub') && <div style={{ marginTop: '50px' }}></div>}
            {/* <div> */}
            {props.children}
            {/* </div> */}

        </>
    )
}

export default Layout